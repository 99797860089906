import * as React from 'react'

const NoCartHeader: React.FC = () => (
  <header>
    <nav className="navbar container-fluid p-0 bg-white">
      <div className="row col-12 mx-auto">
        <a href="/" className="col-12 col-sm-6 col-sm-4 col-lg-3 col-xl-3 mx-auto py-3 text-center">
          <h1 className="font-weight-boldest text-body display-2 no-underline" >ELM FOODS</h1>
        </a>
      </div>
    </nav>
  </header>
)

export default NoCartHeader
