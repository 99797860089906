import React, { createRef, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import NoCartLayout from '../../../layouts/NoCart'
import Testemonials from '../../../components/homepage/Testimonials'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const PlantBasedListEmail = ({ data }) => {
  let emailInput = createRef();
  let emailPage = createRef();
  const marketingPageUrl = "/lp/f1/plant-based-recipes-description"
  let [emailMessage, setEmailMessage] = useState("&nbsp");
  let [emailValid, setEmailValid] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault()

    const email = emailInput.current.value
    const result = await addToMailchimp(email, {
      MERGE9: emailPage.current.value
    })
    console.log(result)
    setEmailValid(result.result == "error")

    setEmailMessage(result.msg)
    navigate(marketingPageUrl)
  }

  return (
    <NoCartLayout>
      <div className="pb-5">
        <div className="section header bg-light">
          <div className="container bg-light pt-5">
            <div className="header-index bg-light mb-0 pb-1">
              <div className="row">
                <div className="col-12 col-md-6">
                  <img src={data.sandwichImage.childImageSharp.fluid.src} className="img-fluid mt-3" />
                </div>
                <div className="col-12 col-md-6 d-flex flex-column justify-content-center mt-3 mt-md-0">
                  <h1 className="font-weight-bold display-1 mb-4" id="primary-promise">
                    Make delicious plant-based eating easy!
                  </h1>
                  <h4 id="expectations">
                    Sign up for our newsletter to get our plant-based grocery starter list and weekly plant-based recipe inspiration.
                  </h4>
                  <form onSubmit={handleSubmit} className="mailchimp-collection-form mt-3 mt-md-0">
                    <input type="hidden" value="lp-make-delicious-plant-based-easy" ref={emailPage} />
                    <div className="input-group input-group-lg">
                      <input type="email" placeholder="Email address" className="input-group py-1 my-3" ref={emailInput} name="email" />
                      <input type="submit" className="btn btn-lg btn-primary btn-block px-3 submit-email" value="Get Cooking!" />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: emailMessage }} className={`${emailValid ? "text-warning mb-0 small" : "text-success mb-0 small"}`} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Testemonials />
      </div>
    </NoCartLayout >
  )
}

export const query = graphql`
  query {
    sandwichImage: file(relativePath: { eq: "food/tempeh-sandwich.jpeg" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
  }
`

export default PlantBasedListEmail
