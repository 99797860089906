import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'
import CartProvider from '../components/shared/CartProvider'
import ProductsProvider from '../components/shared/ProductsProvider'
import favicon from '../images/favicon.ico'

import 'modern-normalize'
import '../styles/normalize'

import NoCartHeader from '../components/NoCartHeader'
import Footer from '../components/Footer'

import 'react-toastify/dist/ReactToastify.css'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const NoCartLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query NoCartLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <div className="h-100 d-flex flex-column">
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords }
          ]}
          link={[{ rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }]}
        >
          <script src={withPrefix('tracking.js')} type="text/javascript" />
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
        </Helmet>
        <CartProvider>
          <ProductsProvider>
            <NoCartHeader title={data.site.siteMetadata.title} />
            <div id="page-content mt-0">{children}</div>
            <Footer />
          </ProductsProvider>
        </CartProvider>
      </div>
    )}
  />
)

export default NoCartLayout
